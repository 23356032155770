import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
export default function useReceipt(emit) {
    const { t } = useI18nUtils();
    const patients_id = ref(router.currentRoute.params.id);
    const refreceiptListTable = ref();
    const items = ref([]);
    const toast = useToast();
    const tableColumns = [
      
       // { key: 'no', label: '#', sortable: false },
        { key: 'receipt_no', label: 'เลขที่ใบเสร็จ', sortable: false, class:'text-left' },
        { key: 'first_name', label: 'Customer Name', sortable: false, class:'text-left' },
        { key: 'start_date', label:'วันที่', sortable: false },
        
        { key: 'opd_code', label: 'รหัส', sortable: false },
        { key: 'status_title', label: 'สถานะ', sortable: false },
        { key: 'gateway_name', label :'Method', sortable:false},
        { key: 'price_total', label: 'ราคารวม', sortable: false },
        { key: 'action', label: 'การจัดการ', sortable: false },
      
  
  
    ];
    const tableReceiptColumns = [
      { field: 'receipt_no', label: 'เลขที่ใบเสร็จ', sortable: false, class:'text-left' },
      { field: 'first_name', label: 'Customer Name', sortable: false, class:'text-left' },
      { field: 'start_date', label:'วันที่', sortable: false },
      
      { field: 'opd_code', label: 'รหัส', sortable: false },
      { field: 'status_title', label: 'สถานะ', sortable: false },
      { field: 'gateway_name', label :'Method', sortable:false},
      { field: 'price_total', label: 'ราคารวม', sortable: false },
      { field: 'action', label: 'การจัดการ', sortable: false },

  



    ];
    const patient = ref()
    const date_from = ref()
    const date_to = ref()
    const perPage = ref(20)
    const totals = ref(0)
    const currentPage = ref(1)
    const dataMeta = computed(() => {
      const localItemsCount = refreceiptListTable.value ? refreceiptListTable.value.localItems.length : 0
      return {
          from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
          to: perPage.value * (currentPage.value - 1) + localItemsCount,
          of: totals.value,
      }
  })
    watch([currentPage, perPage,patient,date_from,date_to], () => {
      fetchData()
    })
    
   
    const fetchData = () => {
      items.value  = [];
      const offset = perPage.value*(currentPage.value-1);
        store
          .dispatch('app-receipt/fetchReceiptData', {
           // patients_id:patients_id.value,
            offset:offset,
            limit:perPage.value,
            patient:patient.value,
            date_from:date_from.value,
            date_to:date_to.value
              /* page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            status: statusFilter.value,*/
          })
          .then(response => {
           
            const  receipts  = response.data.data
            console.log('receipts2',receipts);
            //const total = response.data.total?response.data.total:cats.length;
            //callback(receipts)
            items.value = receipts;
             const total = response.data.total?response.data.total:receipts.length;

            totals.value = total
            
           // totalBlogs.value = total
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching Category' list",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
       
    }
    fetchData();
    
    //fetchReceiptData
    return {
        t,
        patients_id,
        tableColumns,
        refreceiptListTable,
        fetchData,
        currentPage,
        totals,
        perPage,
        dataMeta,
        items,
        tableReceiptColumns,
        patient,
        date_from,
        date_to
        
    }
}