<template>
    <div>
        <b-card no-body>
            <div class="m-2 p-1">
                <b-row>
                    <b-col
                    md="6"
                    class="d-flex justify-content-start"
                    
                    >
                    <h4 class="text-dark font-weight-bold">Receipt</h4>
                    
                    </b-col>
                   
                </b-row>
                <b-row class="form-boder-input ">
                    <b-col
                        md="3"
                    >
                        
                        <div class="demo-vertical-spacing ml-1">
                            <b-form-group
                            label="เลือกลูกค้า(ผู้ป่วย)"
                            label-for="patient"
                            >
                           
                                <b-form-input id="patient" v-model="patient" />
                            </b-form-group>
                        </div>
                    </b-col>
                   
                   
                    <b-col
                        md="3"
                    >
                        
                        <div class="demo-vertical-spacing ml-1">
                            <b-form-group
                            label="From"
                            label-for="from"
                            >
                            <b-form-datepicker
                                id="from"
                                v-model="date_from"
                                type="date"
                            />
                            </b-form-group>
                        </div>
                    </b-col>
                    <b-col
                        md="3"
                    >
                        
                        <div class="demo-vertical-spacing ml-1">
                            <b-form-group
                            label="To"
                            label-for="to"
                            >
                            <b-form-datepicker
                                id="to"
                                v-model="date_to"
                                type="date"
                            />
                            </b-form-group>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </b-card>
        <b-card no-body>
            <div class="m-2">
                <vue-good-table
                    :columns="tableReceiptColumns"
                    :rows="items"
                    ref="refreceiptListTable"
                
                    :select-options="{
                        enabled: false,
                        selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
                        selectionInfoClass: 'custom-class',
                        selectionText: 'rows selected',
                        clearSelectionText: 'clear',
                        disableSelectInfo: true, // disable the select info panel on top
                        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                    }"
                    :pagination-options="{
                            enabled: true,
                            perPage:perPage
                        }"
                
                    
                    >
                    <template
                        slot="table-row"
                        slot-scope="props"
                    >
                        <div
                            v-if="props.column.field === 'receipt_no'"
                            class="text-nowrap"
                            
                            >   
                                <span class="text-nowrap">  <b-link
                            
                            :to="{ name: 'receipt-confirm', params:{id:props.row.id}}"
                        >
                            #{{ props.row.receipt_no }}
                        </b-link> </span>
                        
                        </div>
                        <div
                            v-else-if="props.column.field === 'first_name'"
                            class="text-nowrap"
                            
                            >   
                                <span class="text-nowrap">    {{ props.row.first_name }}  {{ props.row.last_name }} </span>
                        
                        </div>
                        <div
                            v-else-if="props.column.field === 'start_date'"
                            class="text-nowrap"
                            
                            >   
                                <span class="text-nowrap">    {{ formatDateTh(props.row.start_date)   }}  </span>
                        
                        </div>
                        <div
                            v-else-if="props.column.field === 'status_title'"
                            class="text-nowrap"
                            
                            >   
                                <span class="text-nowrap" v-if="props.row.status_id != 5">  {{ props.row.status_title  }}  </span>
                                <span class="text-nowrap text-danger" v-else>  {{ props.row.status_title  }}  </span>
                                <small class="text-nowrap text-danger" v-if="props.row.status_id== 5"><br />(  {{ formatDateTh(props.row.cancel_date)  }}  )</small>
                        
                        </div>
                        
                        <div
                            v-else-if="props.column.field === 'price_total'"
                            class="text-nowrap"
                            
                            >   
                                <span class="text-nowrap">      {{ getCommaDecimal(props.row.price_total)   }}  </span>
                                <p class="text-danger" v-if="props.row.status_id == 2" style="font-size:12px;"> (ชำระแล้ว {{ getCommaDecimal(props.row.deposit)   }})</p>
                        
                        </div>
                        <div v-else-if="props.column.field == 'gateway_name'">
                            <template v-if="props.row.gateways.length > 0">
                                <span v-for="(g,i) in  props.row.gateways" :key="'g-'+props.row.id+'-'+i">
                                    <template v-if="i==0">{{ g.gateway_name }}</template>
                                    <template v-else>, {{ g.gateway_name }}</template>
                                    
                                </span>
                            </template>
                            <tempate v-else>{{  props.row.gateway_name  }}</tempate>
                        </div>
                        <div
                            v-else-if="props.column.field === 'action'"
                            class="text-nowrap"
                            
                            >   
                                <span class="text-nowrap">
                                    <b-button
                                        :id="`tooltip-target5-${props.row.id}`"
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        variant="flat-mute"
                                        class="btn-icon rounded-circle"
                                        :to="{ name: 'receipt-confirm', params:{id:props.row.id}}"

                                    >
                                        <feather-icon icon="DollarSignIcon" />
                                    </b-button>
                                    <b-tooltip :target="`tooltip-target5-${props.row.id}`" triggers="hover" placement="top">
                                        ชำระเงิน
                                    </b-tooltip>
                                    <b-button
                                        :id="`tooltip-target4-${props.row.id}`"
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        variant="flat-mute"
                                        class="btn-icon rounded-circle"
                                        :to="{ name: 'receipt-edit', params:{id:props.row.id}}"

                                    >
                                        <feather-icon icon="EditIcon" />
                                    </b-button>
                                    <b-tooltip :target="`tooltip-target4-${props.row.id}`" triggers="hover" placement="top">
                                        แก้ไข
                                    </b-tooltip>
                                </span>
                        
                        </div>
                    </template>
                    
                    <template
                        slot="pagination-bottom"
                        slot-scope="props"
                    >
                        <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap ">
                            Items per page
                            </span>
                            <b-form-select
                            v-model="perPage"
                            :options="['10','20','50']"
                            class="mx-1"
                            @input="(value)=>props.perPageChanged({currentPerPage:value})"
                            />
                        
                        </div>
                        <div>
                            <b-pagination
                            v-model="currentPage"
                            :total-rows="totals"
                            :per-page="perPage"
                            first-number
                            last-number
                            align="right"
                            
                            class="mt-1 mb-0"
                            
                            >
                            <template #prev-text>
                                <feather-icon
                                icon="ChevronLeftIcon"
                                size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                                />
                            </template>
                            </b-pagination>
                        </div>
                        </div>
                    </template>
                </vue-good-table>
                
            </div>
        </b-card>
    </div>
</template>
<script>
import {
    BCard, BRow, BCol, BCollapse, BSidebar, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
    BInputGroup,BInputGroupPrepend,BInputGroupAppend,VBToggle,BFormDatepicker
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
///import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'


import { ref,onUnmounted } from '@vue/composition-api'

import { formatDateTh,formatDateToMonthShort,getCommaDecimal } from '@core/utils/filter'

import vSelect from 'vue-select'
import router from '@/router'
import receiptStoreModule from '../receiptStoreModule'
import useReceipt from './useReceipt'
//import html2pdf from 'html2pdf.js'
import VueHtml2pdf from 'vue-html2pdf'
export default {
    components:{
        BCard, BRow, BCol, BCollapse, BSidebar, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
    BInputGroup,BInputGroupPrepend,BInputGroupAppend,VBToggle,BFormDatepicker,
    VueGoodTable

    },
    directives:{
        'b-toggle': VBToggle,
        'b-modal': VBModal,
        Ripple

    },
    data(){
        return {
            formatDateTh,
            formatDateToMonthShort,
            getCommaDecimal
        }
    },
    setup(props, { emit }){
        const RECEIPT_STORE_MODULE_NAME = 'app-receipt';
        if (!store.hasModule(RECEIPT_STORE_MODULE_NAME)) store.registerModule(RECEIPT_STORE_MODULE_NAME, receiptStoreModule)
        onUnmounted(() => {
            if (store.hasModule(RECEIPT_STORE_MODULE_NAME)) store.unregisterModule(RECEIPT_STORE_MODULE_NAME)
        }); 
        const {
            t,
            patients_id,
            tableColumns,
            refreceiptListTable,
            fetchData,
            currentPage,
            totals,
            perPage,
            dataMeta,
            items,
            tableReceiptColumns,
            patient,
            date_from,
            date_to

        } = useReceipt();

        return {
            t,
            patients_id,
            tableColumns,
            refreceiptListTable,
            fetchData,
            currentPage,
            totals,
            perPage,
            dataMeta,
            items,
            tableReceiptColumns,
            patient,
            date_from,
            date_to

        }

    }
    
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>